<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增/修改 分类</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>名称</b></label>
            <el-input type="text" v-model="model.NAME" />
          </div>
          <div class="form-group">
            <label><b>排序号</b></label>
            <el-input type="text" v-model="model.SORT" />
          </div>
          <div class="form-group">
            <label><b>大类</b></label>
            <el-select v-model="model.PARENT_ID" placeholder="无" clearable style="display: block">
              <el-option :label="it.NAME" :value="it.ID" v-for="it in pnodes" :key="it.ID" />
            </el-select>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sta: {
        show: false,
        loading: false
      },
      model: {},
      pnodes: []
    }
  },
  methods: {
    init(model) {
      this.sta = { show: true, loading: false }
      this.pnodes = this.$parent.cats
      if (model == undefined || model == null) this.model = {};
      else {
        this.model = JSON.parse((JSON.stringify(model)));
      }
    },
    submit() {
      let self = this;
      self.sta.loading = true;
      this.whale.remote.getResult({
        url: "/api/School/FIX/CatApi/Save",
        data: self.model,
        finally() { self.sta.loading = false; },
        completed: function () {
          self.sta.show = false;
          self.$parent.grid.sea.PARENT_ID = ''
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
