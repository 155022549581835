<template>
<div>
  <div>
    <div class="content-header container-fluid bg-white">
      <div class="row">
        <div class="col-sm-4">
          <h4><i class="ti-hummer"> </i> 维修管理 | 设置分类</h4>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <el-button type="primary" @click="rowEdit()">新增分类</el-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="w-block" style="padding: 2px;">
            <el-select v-model="grid.sea.PARENT_ID" @change="filter" clearable placeholder="请选择大类" >
              <el-option :label="it.NAME" :value="it.ID" v-for="(it,i) in cats" :key="i" />
            </el-select>
          </div>
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1" :page-size="grid.sea.PAGE_SIZE" @page-changed="loadPage">
            <el-table-column prop="ID" label="ID" width="60" />
            <el-table-column prop="PARENT_ID" label="PID" width="60" />
            <el-table-column prop="NAME" label="名称" width="100" />
            <el-table-column prop="FULL_NAME" label="全称" width="300" />
            <el-table-column prop="SORT" label="排序" width="60" align="center" />
            <el-table-column label="操作" >
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)" >修改</el-button>
                <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)" >删除</el-button>
              </template>
            </el-table-column>
          </wgrid>
        </div>
      </div>
    </div>
  </div>
  <div>
    <Edit ref="cat_edit" @on-saved="filter" />
  </div>
</div>
</template>

<script>
import Edit from "./edit";
import Wgrid from "@/components/wgrid";
export default {
  name: "index",
  components: {Wgrid, Edit},
  data(){
    return{
      grid:{
        sea:{
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          TYPE:"",
        },
        ls:[],
        total:0,
        loading:false
      },
      cats:[]
    }
  },
  created() {
    this.getCats();
    this.getList();
  },
  methods:{
    filter(){
      // this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi){
      this.grid.sea.PAGE_INDEX = pi-1;
      this.getList();
    },
    getList(){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getCollection({
        url: "/api/School/FIX/CatApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls=its;
          self.grid.total=n;
          self.grid.loading=false;
        }
      })
    },
    getCats(){
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/School/FIX/CatApi/GetList",
        data:{PARENT_ID:0},
        completed: function (its) {
          self.cats=its;
        }
      })
    },
    rowEdit(r){
      this.$refs.cat_edit.init(r);
    },
    rowDel(r){
      this.whale.delConfirm(()=>{
        let self=this;
        this.whale.remote.getResult({
          url:"/api/School/FIX/CatApi/Delete",
          data:{ID:r.ID},
          completed:function (){
            //self.filter();
            self.grid.ls=self.grid.ls.filter(o=>o.ID!=r.ID)
          }
        })
      });
    }
  }
}
</script>

